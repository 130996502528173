<template>
  <div class="main">
    <div class="container">
      <div class="judges_detail">
        <div class="judges_pic" v-if="info.first_name">
          <img class="img" :src="info.list_img" />
          <div class="name">{{ info.first_name + ' ' + info.last_name }}</div>
          <div class="company">{{ info.position }}</div>
          <div class="title">{{ info.region }}</div>
        </div>
        <div class="judges_info">
          <img :src="info.detail_img" />
          <div class="text" v-html="info.introduction"></div>
          <div class="backBtn" @click="pageBack"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SPEAKERS_DETAIL } from '@/api/index.js';
export default {
  name: 'JudgesDetail',
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      info: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
    getDetail() {
      SPEAKERS_DETAIL({ id: this.id }).then(res => {
        console.log(res);
        this.info = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.judges_detail {
  margin-top: 50px;
  display: flex;
  .judges_pic {
    flex: 0 0 200px;
    width: 200px;
    line-height: 26px;
    .img {
      display: block;
      width: 100%;
    }
    .name {
      margin: 20px auto;
      font-size: 26px;
      line-height: 26px;
    }
    .company {
      font-size: 20px;
    }
    .title {
      font-size: 20px;
      font-style: italic;
    }
  }
  .judges_info {
    margin-left: 50px;
    flex: 1;
    img {
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }
    .text {
      font-size: 18px;
      line-height: 30px;
      color: $font2;
    }
  }
}
@media (max-width: 950px) {
  .judges_detail {
    display: block;
    .judges_pic {
      margin: 0 auto;
      margin-bottom: 50px;
    }
    .judges_info {
      margin-left: 0px;
      flex: 1;
      img {
        display: block;
        width: 100%;
        margin-bottom: 30px;
      }
      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
</style>
